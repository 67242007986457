import firebase from 'firebase/app';
import '@firebase/messaging';
import '@firebase/analytics';

let messaging;

const config = {
  apiKey: "AIzaSyA0nCIv-FVjIISOLIB0bFoJmv6o3ajjt1A",
  authDomain: "farmacia-demo-decfe.firebaseapp.com",
  databaseURL: "https://farmacia-demo-decfe.firebaseio.com",
  projectId: "farmacia-demo-decfe",
  storageBucket: "farmacia-demo-decfe.appspot.com",
  messagingSenderId: "820852668219",
  appId: "1:820852668219:web:379c81aff7a98909ed291e",
  measurementId: "G-L4GGVZHRNM"
};

firebase.initializeApp(config);

if(firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
  messaging.usePublicVapidKey('BB8ksw36Nl5v9hyHg1Qlw_A9wpkLPgW9FZn1rb0hVqpTE_UqE-XZRO3p4ES3W4y__sKaCPEkRqGyZXp_2SEEUQ8');
}

export {
  messaging
};