import React, { Component } from 'react';

import moment from 'moment';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonPage,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonGrid,
  IonRow,
  IonCard,
  IonCardTitle,
  IonCardHeader,
  IonCardContent,
  withIonLifeCycle,
  IonCol,
  IonLabel,
} from '@ionic/react';

import ShopEvent from '../services/shopevent';
import ProfileService from '../services/profile';
import ReservationsService from '../services/reservations';

class EventDetails extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.files = React.createRef();
    this.pickedDateRef = React.createRef('');
    this.selectedTimeRef = React.createRef('');
    this.selectedStaffIdRef = React.createRef('');
    this.noteRef = React.createRef('');
    this.date = React.createRef('');
    this.serviceRef = React.createRef('');
    this.profileRef = React.createRef('');

    this.state = {
      event: [],
      shopServiceModal: false,
      availableDate: [],
      pickedDateTime: null,
      isModalOpen: false,
      isActive: '',
      calendarDayProgression: [],
    };

    this.setService = this.setService.bind(this);
    this.textHandler = this.textHandler.bind(this);
    this.requestService = this.requestService.bind(this);
  }

  async ionViewWillEnter() {
    if (!!this.props.loader) this.props.loader.current.show();
    const { id, data } = this.props.match.params;

    await this.setService(this.props.match.params.id, false);
    this.setState({ eventDate: data });
    try {
      const { id } = this.props.match.params;

      const {
        data: { data: profile },
      } = await ProfileService.me();

      this.profileRef.current = profile;
      let response = await ShopEvent.find(id);

      let {
        data: { data: event },
      } = response;
      this.setState({ event });
    } catch (e) {
      console.error(e);
    } finally {
      this.props.loader.current.dismiss();
    }
  }

  async ionViewWillLeave() {
    this.setState({ isModalOpen: false });
    this.setState({ pickedDateTime: null });
    this.setState({ availableDate: null });
    this.setState({ isActive: '' });
    this.setState({ calendarDayProgression: [] });

    this.serviceRef.current = '';
    this.profileRef.current = '';
    this.selectedTimeRef.current = '';
    this.pickedDateRef.current = '';
    this.selectedStaffIdRef.current = '';
    this.noteRef.current = '';
  }

  async textHandler(event) {
    await this.setState({ [event.target.name]: event.detail.value });
  }

  async setService(shop_service_id) {
    try {
      const response = await ProfileService.me();

      const { data: profile } = response.data;

      await this.setState({ profile });
    } catch (error) {
      console.error(error);
    }

    await this.setState({
      shop_service_id,
      shopServiceModal: true,
    });
  }

  async requestService(e) {
    try {
      let shopservice = {
        shop_service_id: this.state.shop_service_id,
        shop_service_name: this.state.event.find((service) => service.id === this.state.shop_service_id).name,
        note: this.state.note,
      };

      await ShopEvent.create(shopservice);
      this.setState({
        shop_service_id: undefined,
        note: '',
        shopServiceModal: false,
      });

      this.props.toast.current.add({
        message: `Richiesta inviata correttamente`,
      });
    } catch (error) {
      console.error(error);
      this.props.toast.current.add({
        message: `Impossibile inviare la richiesta`,
        color: 'warning',
      });
    }
  }

  async fetchDateTime(serviceId) {
    try {
      let {
        data: { data: profile },
      } = await ProfileService.me();

      await this.setState({ profile });
    } catch (error) {
      console.error(error);
    }
    const { id, data } = this.props.match.params;

    this.props.history.push(`/app/reservations/event/${id}/${data}/${serviceId}`);
  }

  async submitHandler(that) {
    let endAppointment = moment(`${that.pickedDateRef.current} ${that.selectedTimeRef.current}`)
      .add(that.serviceRef.current.duration, 'm')
      .subtract(1, 's')
      .format('HH:mm:ss');

    const { id } = this.props.match.params;

    try {
      await ReservationsService.saveReservation({
        user_id: that.profileRef.current.id,
        service_id: that.serviceRef.current.id,
        event_id: id,
        staff_id: that.selectedStaffIdRef.current,
        day: that.pickedDateRef.current,
        start_appointment: that.selectedTimeRef.current,
        end_appointment: endAppointment,
        note: that.noteRef.current,
      });

      await that.props.toast.current.add({ message: `Prenotaziona effettuata correttamente.` });
      await this.fetchDateTime(that.pickedDateRef.current);
      that.setState({ isModalOpen: false });
      that.props.history.push('/app/reservations');
    } catch (e) {
      console.error(e);
      that.props.toast.current.add({
        message: `Problema nella conferma della prenotazione.`,
        color: 'danger',
      });

      delete that.props.toast.current.onDidDismiss;
    }
  }

  formatDate(date) {
    return new Date(date).toLocaleDateString('it-IT');
  }

  reset() {
    this.pickedDateRef.current = '';
    this.setState({ pickedDateTime: null });
    this.setState({ isActive: '' });
  }

  render() {
    const { event } = this.state;
    const { id, data } = this.props.match.params;
    const formattedDate = this.formatDate(data);
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/app/events" />
            </IonButtons>
            <IonTitle>{event ? `Dettaglio Evento - ${event.name}` : 'Dettaglio Evento'}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <p className="ion-padding-top ion-padding-start ion-padding-end">
            L'evento selezionato {event.name} si terra il {formattedDate}.
          </p>
          <IonGrid lines="full" className="ion-no-margin ion-no-padding">
            <IonRow>
              {event.service &&
                event.service.map((service, i) => {
                  return (
                    <IonCol
                      size="12"
                      size-md="6"
                      size-lg="4"
                      key={i}
                      onClick={() => {
                        this.fetchDateTime(service.id);
                      }}
                    >
                      <IonCard>
                        <IonCardContent>
                          <IonCardHeader className="ion-no-padding">
                            <IonCardTitle>{service.name}</IonCardTitle>
                          </IonCardHeader>

                          <IonCardContent className="ion-no-padding">{service.description}</IonCardContent>
                          <IonToolbar>
                            <IonLabel slot="start">{service.price} €</IonLabel>
                            <IonLabel slot="end">Min. {service.duration}</IonLabel>
                          </IonToolbar>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                  );
                })}
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(EventDetails);
